<template>
  <div class="enroll" v-if="!state.success && state.canEnroll">
    <div class="enroll-bg">
      <div class="title">{{ $t('EnrollmentInfor') }}</div>
    </div>
    <div class="main">
      <a-spin :spinning="state.loading">
        <div class="detail">
          <div class="title">{{ state.info.enrollName }}</div>
          <div class="time" v-if="state.info.isLimit">
            <ClockCircleOutlined />
            {{ dateFormat(state.info.limitStartTime) }}
            ~
            {{ dateFormat(state.info.limitEndTime) }}
          </div>
          <div class="intro" v-html="state.info.content"></div>
        </div>
        <div class="container">
          <collect v-if="state.fieldLen" layout="vertical" ref="collectRef" />
          <questionnaire v-if="state.questionLen" ref="questionnaireRef" />
        </div>
        <div class="submit-btn">
          <a-button size="large" :loading="loading" :disabled="state.disabled" type="primary" @click="submit">
            {{ state.btnTip || $t('XB_Signup') }}
          </a-button>
        </div>
      </a-spin>
    </div>
  </div>
  <div class="success" v-if="state.success">
    <CheckCircleOutlined />
    <div class="text">{{ $t('XB_SignUpSuccessfully') }}</div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { dateFormat } from "@/utils/tools";
import { getEnrollDetail, submitEnroll } from "@/api/noAuth";
import collect from "./collect.vue";
import questionnaire from "@/views/questionnaire/detail.vue";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const store = useStore();
const route = useRoute();
const langActive = computed(() => store.getters.lang);
const state = reactive({
  id: Number(route.query.id || 0),
  customerId: Number(route.query.customerId || 0),
  info: {},
  loading: false,
  success: false,
  canEnroll: false,
  locale: "zh_CN",
  fieldLen: 0,
  questionLen: 0,
  btnTip: '',
  disabled: false,
});
const collectRef = ref(null);
const questionnaireRef = ref(null);
async function getDetail() {
  const params = {
    cId: state.customerId,
    enrollId: state.id,
  };
  const res = await getEnrollDetail(params);
  // state.locale = langActive.value.replace(/-/, "_");
  if (res.ret === 0) {
    state.canEnroll = true;
    const { fields, questions, ...data } = res.data;
    if (data.isLimit) {
      const current = Date.parse(new Date()) / 1000;
      const isStart = current >= data.limitStartTime;
      const isEnd = current <= data.limitEndTime;
      if (!isStart) state.btnTip = $t('CM_StartedYet');
      if (!isEnd) state.btnTip = $t('XB_Signup') + $t('XB_HasEnded');
      state.btnTip && proxy.$message.info(state.btnTip)
      state.disabled = !(isStart && isEnd);
    }
    state.info = data;
    const fieldsTemp = fields || [];
    const fieldList = fieldsTemp.map((item) => {
      const { memberField, ...col } = item;
      memberField.dataRequired = col.dataRequired;
      memberField.tipContent = col.tipContent;
      const langName = JSON.parse(col.langName);
      memberField.langName = langName;
      if (langName[state.locale]) {
        memberField.defaultName = memberField.fieldName;
        memberField.fieldName = langName[state.locale];
      }
      return memberField;
    });
    const questionsTemp = questions || [];
    questionsTemp.forEach((item) => {
      item.questionId = item.enrollQuestionId;
    });
    state.fieldLen = fieldList.length;
    state.questionLen = questionsTemp.length;
    nextTick(() => {
      collectRef.value && collectRef.value.getDetail(fieldList);
      questionnaireRef.value && questionnaireRef.value.getDetail(questionsTemp);
    })
  } else {
    if (res.data.status === 5) {
      return proxy.$message.error($t("XB_Signup") + $t("not_exist"));
    }
    if (res.data.status === 1) {
      return proxy.$message.error(
        $t("NotWithinTheTimeframe", { type: $t("XB_Signup") })
      );
    }
    proxy.$message.error(res.msg);
  }
}
getDetail();
async function submit() {
  let fields = null;
  let questions = null;
  let options = [];
  if (collectRef.value) fields = await collectRef.value.getResult();
  if (questionnaireRef.value) questions = await questionnaireRef.value.getResult();
  
  if (Array.isArray(fields)) {
    if (!fields.length) return;
    fields.forEach((item) => {
      item.fieldSource = 1;
      if (item.defaultName) {
        item.fieldName = item.defaultName;
        delete item.defaultName;
      }
    });
    options = fields;
  };
  if (Array.isArray(questions)) {
    if (!questions.length) return;
    questions.forEach((item) => {
      let arr = [];
      item.scoreList.forEach((op) => {
        if (op.score === 1) {
          let str = op.option
            ? `${op.option}${op.remark ? "/" + op.remark : ""}`
            : op.remark;
          arr.push(str);
        }
      });
      const p = {
        fieldKey: item.questionID + "",
        fieldName: item.title,
        fieldValue: arr.join(","),
        fieldSource: 2,
        fieldType: item.questionType,
      };
      options.push(p);
    });
  };

  if (!options.length) return;
  const params = {
    cId: state.customerId,
    enrollId: state.id,
    options: options,
  };
  state.loading = true;
  const res = await submitEnroll(params);
  if (res.ret === 0) {
    proxy.$message.success($t("XB_SignUpSuccessfully"));
    state.success = true;
    state.canEnroll = false;
  }
  state.loading = false;
}
</script>
<style lang="less" scoped>
.enroll {
  padding-bottom: 40px;
}
.enroll-bg {
  position: relative;
  height: 200px;
  background-color: #4996fe;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    height: 200px;
    background: #4996fe url("~@/assets/image/collect-bg.png") no-repeat;
    background-size: contain;
  }
  .title {
    position: absolute;
    top: 25%;
    right: 41%;
    font-size: 64px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.main {
  .mixinWrap(800px);
  .detail {
    margin: 30px 0;
    .title {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
    }
    .time {
      margin: 10px 0;
      color: #666;
      text-align: center;
    }
  }
  .container {
    ::v-deep(.content) {
      width: 800px;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .submit-btn {
    margin-top: 20px;
    text-align: center;
  }
}
.success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #19be6b;
  font-size: 80px;
  .text {
    font-size: 22px;
    font-weight: 600;
  }
}
</style>
